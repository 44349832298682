.separator {
   width: 120px;
   background-color: #f7f7f7;
   padding: 5px;
   margin: 20px auto;
   border-radius: 20px;
   font-size: 13px;
   text-align: center;
}

/* .separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #0000003a;
}
.separator::before {
    margin-right: 1em;
}
.separator::after {
    margin-left: 1em;
} */

.chatPwc-section-scroller {
   /* background-color: #F0F2F5; */
   /* background-image: url(../../assets/images/chat-bg-2.png); */
   /* background-size: cover; */
}

.chat-section-scroller {
   background-color: #F0F2F5;
   /* background-image: url(../../assets/images/chat-bg-2.png); */
   /* background-size: cover; */
}


/* 962 X 657 small  */

@media (max-height: 753px) {
   .chat-section-scroller {
      height: 68vh !important;
      min-height: 68vh !important;
   }

   .chatPwc-section-scroller {
      height: 68vh !important;
      min-height: 68vh !important;
   }
}

@media (max-height: 705px) {
   .chat-section-scroller {
      height: 67vh !important;
      min-height: 67vh !important;
   }

   .chatPwc-section-scroller {
      height: 67vh !important;
      min-height: 67vh !important;
   }
}

@media (max-height: 690px) {
   .chat-section-scroller {
      height: 66vh !important;
      min-height: 66vh !important;
   }

   .chatPwc-section-scroller {
      height: 66vh !important;
      min-height: 66vh !important;
   }
}

@media (max-height: 647px) {
   .chat-section-scroller {
      height: 64vh !important;
      min-height: 64vh !important;
   }

   .chatPwc-section-scroller {
      height: 64vh !important;
      min-height: 64vh !important;
   }
}

@media (max-height: 620px) {
   .chat-section-scroller {
      height: 63vh !important;
      min-height: 63vh !important;
   }

   .chatPwc-section-scroller {
      height: 63vh !important;
      min-height: 63vh !important;
   }
}

/* Message input */

/* aside.emoji-picker-react {
    width: auto;
} */

/* html {
    height: 100%;
 }
 
 body {
    background: #633;
    color: #fff;
    display: flex;
    min-height: 100%;
 }
 
 #app {
    flex: 1;
 } */

.container {
   display: flex;
   flex: 1;
   flex-direction: column;
   width: 98%;
}

.table-row {
   border-top: 1px solid rgba(255, 255, 255, .2);
}

.ReactVirtualized__Table__headerRow {
   border: 0;
   color: #ff0;
}

.list {
   padding: 10px;
}

.row {
   border-bottom: 1px solid #ebeced;
   text-align: left;
   margin: 5px 0;
   display: flex;
   align-items: center;
}

.image {
   margin-right: 10px;
}

.content {
   padding: 10px;
}

.ReactVirtualized__List:focus {
   outline: none;
}

.highlight {
   background-color: yellow;
}